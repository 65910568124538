<template>
  <div>
    <file-upload
      class="button is-primary"
      extensions="png"
      accept="image/png"
      @input="uploadFile">
      {{ buttonTitle }}
    </file-upload>
  </div>
</template>

<script>
//import uuid from "uuid"
import VueUploadComponent from 'vue-upload-component';
import Storage from "@/firebase/storage"

export default {
  components: {
    "file-upload": VueUploadComponent
  },
  data() {
    return {
      value: Object,
    }
  },
  props: {
    multiple: {
      type: Boolean,
      default: false
    },
    isSticker: {
      type: Boolean,
      default: false
    },
    buttonTitle: {
      type: String,
      default: "Add Image"
    }
  },
  methods: {
    uploadFile(files) {
      let file = files[0];

      let newImage = {};
      newImage.id = file.name;
      let basePath = this.isSticker ? "images/stickers" : "images/recipes";
      newImage.path = `${basePath}/${newImage.id}`
      this.value = newImage;

      let uploadTask = Storage.ref().child(newImage.path).put(file.file);

      uploadTask.on('state_changed',
        (snapshot) => {
          // Observe state change events such as progress, pause, and resume
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log('Upload is ' + progress + '% done');
          // switch (snapshot.state) {
          //   case Storage.TaskState.PAUSED: // or 'paused'
          //     console.log('Upload is paused');
          //     break;
          //   case Storage.TaskState.RUNNING: // or 'running'
          //     console.log('Upload is running');
          //     break;
          // }
        },
        (error) => {
          // Handle unsuccessful uploads
          this.$buefy.toast.open({type: 'is-danger', message: `Error saving recipe: ${error}`})
        },
        () => {
          // Handle successful uploads on complete
          // For instance, get the download URL: https://firebasestorage.googleapis.com/...
          uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
            console.log('File available at', downloadURL);
            newImage.src = downloadURL
            this.value = newImage;
            this.$emit('input', newImage)
          });
        }
      );
    },
  }
}
</script>
