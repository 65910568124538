<template>
  <div>
    <b-notification
        v-model="isError"
        type="is-danger"
        aria-close-label="Close notification"
        :closable="false">
        <b>Error fetching tip!</b><br />Try refreshing the page. If the error persists check your network connection or let BM know.
    </b-notification>
    <b-loading :is-full-page="true" v-model="isLoading"></b-loading>
    <div v-if="!isLoading && !isError" class="content m-5">
        <div class="columns">
          <div class="column">
            <h1 class="title is-1">{{ title }}</h1>
          </div>
          <div class="column is-narrow">
            <b-field horizontal>
                <b-select v-model="tip.status" placeholder="Status" @input="save">
                    <option>draft</option>
                    <option>test</option>
                    <option>fix</option>
                    <option>live</option>
                  </b-select>
             </b-field>
         </div>
          <div class="column is-narrow">
            <b-button outlined type="is-primary" class="right" icon-left="check" @click="saveAndClose">Save & Close</b-button>
          </div>
          <div class="column is-narrow">
            <b-button type="is-primary" class="right" icon-left="content-save" @click="save">Save</b-button>
          </div>
        </div>
        <div class="columns">
            <section id="general" class="column">

            <h2 class="subtitle is-3">General</h2>
            <b-field horizontal label="Title">
              <b-input v-model="tip.title" />
             </b-field>
            <b-field horizontal label="Body">
             <editor v-model="tip.body" @input="saveDebounced"/>
            </b-field>
            <b-field horizontal label="Background">
              <b-select placeholder="Choose colour" icon="format-color-fill"
                v-model="tip.background_color"
                @input="saveDebounced">
                <option
                      v-for="option in colors"
                      :value="option"
                      :key="option.name">
                      {{ option.name }}
                  </option>
                </b-select>
            </b-field>
            <b-field horizontal label="Foreground" >
              <b-select placeholder="Choose colour" icon="palette"
                v-model="tip.foreground_color"
                @input="saveDebounced">
                <option
                      v-for="option in colors"
                      :value="option"
                      :key="option.name">
                      {{ option.name }}
                  </option>
                </b-select>
            </b-field>
            <b-field horizontal label="Sticker">
              <figure class="m-0">
                <img :src="stickerSrc" width="125px"/>
              </figure>
              <ImageUpload horizontal
                v-model="tip.sticker"
                :isSticker="true"
                buttonTitle="Update Sticker"
                @input="save"
                />
            </b-field>
            <b-field horizontal label="Sticker Position">
                <b-select v-model="tip.sticker_position" placeholder="Position" @input="saveDebounced">
                    <option>top-left</option>
                    <option>top-right</option>
                    <option>top-middle</option>
                    <option>bottom-left</option>
                    <option>bottom-right</option>
                    <option>bottom-middle</option>
                  </b-select>
             </b-field>
           </section>

           <!-- Preview -->
           <section id="preview-section" class="column is-half">
             <b-field >
               <div id="background" :style="backgroundStyle">
                 <div id="foreground"
                    class="card is-flex"
                    :class="cardFlexDirection"
                    :style="foregroundStyle">
                   <figure class="m-0" :style="stickerStyle">
                     <img :src="stickerSrc" style="max-width:125px; max-height:125px"/>
                   </figure>
                   <div class="px-5 py-3">
                     <h1 :style="foregroundTextStyle" class="mb-1" >{{ tip.title }}</h1>
                     <h5 :style="foregroundTextStyle" v-html="tip.body"  />
                   </div>
                   <div style="height: 100px;"></div>
                 </div>
               </div>
             </b-field>
           </section>
         </div>

    </div>
  </div>
</template>

<script>
import debounce from 'lodash/debounce'
import Editor from "@/components/BaseEditor"
import db from "@/firebase/db"
import Colours from "@/models/Colours"
import DateFormatter from "@/formatters/DateFormatter"
import ImageUpload from "@/components/ImageUpload"

export default {
  name: 'TipEncouragementView',
  components: {
    Editor,
    ImageUpload,
  },
  props: [
    'id'
  ],
  data() {
    return {
      isLoading: true,
      isError: false,
      tip: {}
    };
  },
  computed: {
    title() {
      return this.tip.title ?? "New Tip";
    },
    stickerSrc() {
      return (this.tip.sticker != null) ? this.tip.sticker.src : null;
    },
    colors() {
      return Colours.all;
    },
    backgroundStyle() {
      if (this.tip.background_color == null) {
        return {
          padding: "20px",
          backgroundColor: "#333333"
        }
      }
      return {
        padding: "20px",
        backgroundColor: this.tip.background_color.colour
      }
    },
    foregroundStyle() {
      if (this.tip.foreground_color == null) {
        return { backgroundColor: "#F2F9EE" }
      }
      return { backgroundColor: this.tip.foreground_color.colour }
    },
    foregroundTextStyle() {
      if (this.tip.foreground_color == null) {
        return { color: "#333333"}
      }
      return { color: this.tip.foreground_color.invertTheme == "dark" ? "#333333":"#F2F9EE",}
    },
    cardFlexDirection(){
      if (this.tip.sticker_position == null) {
        return "is-flex-direction-column-reverse"
      }
      return this.tip.sticker_position.includes('bottom') ? 'is-flex-direction-column-reverse':'is-flex-direction-column';
    },
    stickerStyle() {
      if (this.tip.sticker_position == null) {
        return null;
      }
      if (this.tip.sticker_position.includes("left")){
        return { textAlign: "left"};
      } else if (this.tip.sticker_position.includes("right")){
        return { textAlign: "right"};
      } else{
        return { textAlign: "center"};
      }
    }
  },
  created: function () {
    // fetch our meal_plan
    db.collection('tips_encouragements')
      .doc(this.id)
      .get()
      .then(snapshot => {
        if (snapshot.exists) {
          this.tip = snapshot.data();
        }else {
          this.isError = true; // show our error
        }
        this.isLoading = false; // remove our loader
      })
      .catch((error) => {
        this.isError = true;
        this.$buefy.notification.open({type: 'is-danger', message: `Error fetching TipEncouragement: ${error}`})
      });
  },
  methods: {
    clearDate () {
      this.tip.date = null
    },
    formatDate(dateTime) {
      return DateFormatter.displayDate(dateTime, false);
    },
    saveDebounced: debounce(function () {
        this.save()
      }, 1000),
    async saveAndClose(){
      this.save()
        .then(() => {
          this.$router.go(-1)
        })
    },
    async save(){
      db.collection('tips_encouragements')
        .doc(this.id)
        .set(this.tip)
        .then(() => {
          this.$buefy.toast.open({type: 'is-success', message: `Tip successfully updated!`})
        })
        .catch((error) => {
          this.$buefy.toast.open({type: 'is-danger', message: `Error saving Tip: ${error}`})
        });
    },
  }
}


</script>

<style>
#preview-section {
  text-align: center;
}
#background {
  height: 100%;
}
#foreground {
  border-radius: 16px;
  padding: 16px;
  height: 100%;
}
.mt-minus {
  margin-top: -4rem !important;
}
</style>
